import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CONSTANTS } from 'src/constants'

const SelectedUserContext = React.createContext({
  selectedUser: null, setSelectedUser: () => {},
})

const SelectedUserContextProvider = ({ children }) => {
  const [selectedUser, setSelectedUser] = useState(() => localStorage.getItem(CONSTANTS.SELECTED_USER))
  const value = { selectedUser, setSelectedUser }

  return (
    <SelectedUserContext.Provider value={ value }>
      { children }
    </SelectedUserContext.Provider>
  )
}

SelectedUserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export {
  SelectedUserContext,
  SelectedUserContextProvider,
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CONSTANTS } from '../constants'


const RoleContext = React.createContext({
  role: null, setRole: () => {},
})

const RoleContextProvider = ({ children }) => {
  const [role, setRole] = useState(() => localStorage.getItem(CONSTANTS.CURRENT_USER_TIER))

  return (
    <RoleContext.Provider value={{ role, setRole }}>
      { children }
    </RoleContext.Provider>
  )
}

RoleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export {
  RoleContext,
  RoleContextProvider,
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'

const SelectedDashboardUserContext = React.createContext({
  selectedDashboardUser: null, setSelectedDashboardUser: () => {},
})

const SelectedDashboardUserContextProvider = ({ children }) => {
  const [selectedDashboardUser, setSelectedDashboardUser] = useState()
  const value = { selectedDashboardUser, setSelectedDashboardUser }

  return (
    <SelectedDashboardUserContext.Provider value={ value }>
      { children }
    </SelectedDashboardUserContext.Provider>
  )
}

SelectedDashboardUserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export {
  SelectedDashboardUserContext,
  SelectedDashboardUserContextProvider,
}
